<template>
  <div>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${dataList.length})` }}
      </h6>
      <b-row
        align-h="center"
        class="my-2"
      >
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <master-select-input 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="3">
          <agent-select-input
            :value="selectedAgent"
            :master-id="selectedMaster"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="dataList"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(userAgent)="data">
          {{ data.value.agent.name }}
        </template>
        <template #cell(id)="data">
          {{ data.item.userAgent.username }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>

        <template #cell(browserUserAgent)="data">
          <b-button
            :id="`browserUserAgent-${data.item.id}`"
            size="sm"
            variant="light"
          >
            <feather-icon
              icon="AirplayIcon"
            />
          </b-button>
          <b-popover
            :target="`browserUserAgent-${data.item.id}`"
            triggers="hover"
            placement="left"
            variant="light"
          >
            {{ data.value }}
          </b-popover>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row>
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'User Accessibility',
  },
  data() {
    return {
      selectedMaster: '',
      selectedAgent: '',
      search: '',
      selectedStatus: '',
      statusOptions: [
        { name: 'สำเร็จ', value: 'success' },
        { name: '-', value: 'active' },
        { name: '-', value: '-' },
      ],
      currentPage: 1,
      selectedLimit: 20,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: 'เวลา',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'userAgent',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: 'ยูสเซอร์เนม',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'ipAddress',
          label: 'IP',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'browserUserAgent',
          label: 'เบราว์เซอร์',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.user.isFetchingUserAccess,
    }),
    ...mapGetters(['userAccess']),
    dataList() {
      return this.userAccess.items || []
    },
    pagination() {
      return this.userAccess.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchUserAccess']),
    fetchData() {
      this.fetchUserAccess({
        limit: this.selectedLimit, 
        page: this.currentPage,
        search: this.search, 
        masterId: this.selectedMaster,
        agentId: this.selectedAgent, 
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
  },
}
</script>
